import React from 'react';
import classNames from 'classnames';
import { useEffect } from 'react';

import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { registerLoader, unregisterLoader } from 'shared/components/loading/loading.actions';

import './loading.component.scss';

interface OwnProps {
  alwaysOn?: boolean;
  isInline?: boolean;
  name: string;
}

type Props = OwnProps;

export const Loader: React.FC<Props> = ({name, isInline, alwaysOn}) => {
  const dispatchRegisterLoader = useDispatcher(registerLoader);
  const dispatchUnregisterLoader = useDispatcher(unregisterLoader);

  useEffect(() => {
    dispatchRegisterLoader(name);
    return () => { !alwaysOn && dispatchUnregisterLoader(name) };
  }, []);

  const visible = useSelector(state => Boolean(state.loading[name]));
  const appInitialized = useSelector(state => state.app.initialized);

  return (
    // NOTE: main loader will be visible if the app is still initializing
    <div className={classNames(`loader-${name}`, 'loading-indicator', {
      hidden: (name === 'main' ? (!visible && appInitialized) : !alwaysOn && !visible),
      inline: isInline
    })}>
      <div className="loading-indicator-background"/>
      <div className="loading-indicator-body">
        <h3 className="loading-title">Loading...</h3>
        <div className="spinner">
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"/>
            <div className="sk-cube sk-cube2"/>
            <div className="sk-cube sk-cube3"/>
            <div className="sk-cube sk-cube4"/>
            <div className="sk-cube sk-cube5"/>
            <div className="sk-cube sk-cube6"/>
            <div className="sk-cube sk-cube7"/>
            <div className="sk-cube sk-cube8"/>
            <div className="sk-cube sk-cube9"/>
          </div>
        </div>
      </div>
    </div>
  );
};
